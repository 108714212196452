.filter .react-datepicker__input-container > input {
  font-size: 10px !important;
  font-weight: 500 !important;
  outline: none !important;
  height: 32px !important;
  cursor: pointer !important;
  width: 100% !important;
  background-color: #f9f9f9;
  padding-left: 8px !important;
}

.react-datepicker {
  font-family: Atakk, sans-serif !important;
}

.react-datepicker__year-option .react-datepicker__navigation--years-previous {
  background: url(../images/dropdown-grey.svg) no-repeat;
  border: none;
  inset: 0 !important;
  width: 16px;
  height: 16px;
}

.react-datepicker__year-option .react-datepicker__navigation--years-upcoming {
  background: url(../images/dropdown-grey.svg) no-repeat;
  border: none;
  transform: rotate(180deg);
  inset: 0 !important;
  inset: 0 !important;
  width: 16px;
  height: 16px;
}
