// to override rsuite's font and anchor tag hover style
* {
  font-family: 'Atakk' !important;
}

a:hover {
  text-decoration: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.5;
}

p + p {
  margin-top: 0;
}

aside .active {
  background-color: #ebf1fc;
}

.activeCompanyTab {
  position: relative;
  color: #3977de !important;
  display: inline-block;
  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    height: 0;
    bottom: 0;
    border-bottom: 2px solid #3977de;
    border-radius: 32px;
  }
}

.active span {
  color: #3977de;
}

.active svg path {
  stroke: #3977de;
}

select {
  appearance: none;
  background-image: url('./assets/images/dropdown-grey.svg');
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 16px;
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact;
  height: 48px;
  outline: none;
  border-radius: 4px;
}

.disabled {
  color: #d2d2d3 !important;
}

option {
  font-size: 16px;
}

// hide horizontal scrollbar
.company-nav::-webkit-scrollbar {
  display: none;
}

.company-nav {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

// hide steps for number input
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.google-signin-btn {
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.08);
}

// sidebar styling for mobile
.sidebar-mobile {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  margin-block: 0 !important;
  max-width: 15rem !important;
  text-align: start !important;
  padding: 0 !important;
}

// rsuite styling
.rs-picker-default .rs-btn,
.rs-picker-default .rs-picker-toggle,
.rs-picker-input .rs-btn,
.rs-picker-input .rs-picker-toggle {
  background: transparent !important;
  border: none !important;
  svg {
    display: none;
  }
}

.rs-picker-toggle-textbox {
  background: transparent !important;
  border: none !important;
  outline: none;
  border-color: transparent !important;
  padding: 0 !important;
  font-size: 14px;
  font-weight: 500;
}

.rs-picker-toggle-active {
  border-color: transparent !important;
  box-shadow: none !important;
}

.rs-picker-default .rs-picker-toggle.rs-btn-lg {
  padding: 0 !important;
}

.rs-picker-toggle.rs-btn-lg .rs-picker-toggle-textbox {
  padding-left: 0 !important;
  font-size: 14px;
  font-weight: 500;
  color: #677189 !important;
}

.rs-picker-toggle .rs-picker-toggle-placeholder {
  font-size: 14px;
  font-weight: 500;
  color: #677189;
}

.rs-btn-link {
  color: #3977de !important;
}

.rs-picker-toggle-wrapper:focus {
  outline: none;
}

.rs-btn-primary {
  background-color: #3977de !important;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  box-shadow: inset 0 0 0 1px #3977de !important;
}

.rs-picker-toggle-value {
  color: #677189 !important;
  font-size: 14px;
  font-weight: 500;
}

.rs-picker-date .rs-picker-toggle.rs-btn-sm,
.rs-picker-daterange .rs-picker-toggle.rs-btn-sm {
  padding: 0 !important;
}

.rs-anim-fade.rs-anim-in {
  z-index: 11;
}

// react toastify styling
.Toastify__toast--success {
  background-color: #3977de !important;
  font-weight: 700 !important;
  max-height: 20px !important;
  font-size: 0.8rem;
  color: white !important;
}

.Toastify__toast--error {
  background-color: #bb371a !important;
  font-weight: 700 !important;
  font-size: 0.8rem;
  color: white !important;
}

.Toastify__progress-bar {
  visibility: hidden;
}
.Toastify__toast-icon {
  display: none !important;
}
.Toastify__close-button svg {
  fill: white !important;
}

.Toastify__toast-theme--light {
  width: 368px !important;
  right: 45px !important;
}
// dashboard chart styling
.apexcharts-tooltip {
  background: #fff !important;
  color: #1b2a4e !important;
  box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08),
    0px 8px 16px rgba(50, 50, 71, 0.06) !important;
  border: 1px solid #fff !important;
}
