/* multiselect */
.react-select__multi-value {
  background-color: #3f7cdb !important;
  color: #d9e5f8;
  border-radius: 4px !important;
  gap: 10px;
  align-items: flex-start !important;
  padding: 10px !important;
  margin: 0 !important;
}

.react-select__multi-value__label {
  color: inherit !important;
  font-size: 10px !important;
  font-weight: 500;
  line-height: 12px;
  padding: 0 !important;
  position: relative;
  top: 3px;
}

.react-select__multi-value__remove {
  padding: 0 !important;
}

.react-select__multi-value__remove:hover {
  background-color: transparent !important;
}

.react-select__control--is-focused:active,
.react-select__control--is-focused:focus-within {
  outline-color: #3f7cdb !important;
  outline-width: 1px !important;
  border-color: #3f7cdb !important;
}

.react-select-new-page__menu-list {
  height: auto;
}

.react-select__input-container,
.react-select-new-page__input-container {
  padding: 0 !important;
  margin: 0 !important;
}

.react-select__input {
  height: auto !important;
}

.react-select__placeholder,
.react-select-new-page__placeholder {
  font-size: 14px !important;
  color: #8e90a9 !important;
}

.react-select__value-container,
.react-select-new-page__value-container {
  gap: 8px;
  padding: 0 !important;
}

.react-select__value-container--is-multi {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.react-select__option,
.react-select-new-page__option,
.react-select__single-value,
.react-select-new-page__single-value {
  font-size: 14px !important;
  color: #4a4a68 !important;
}

.react-select__option--is-focused,
.react-select-new-page__option--is-focused,
.react-select__option:hover .react-select-new-page__option:hover {
  background-color: #f1f1f1 !important;
}

.react-select__option--is-selected,
.react-select-new-page__option--is-selected {
  background-color: #333442 !important;
  color: #fff !important;
}

.react-select__option--is-selected:hover,
.react-select-new-page__option--is-selected:hover {
  background-color: #333442 !important;
  color: #fff !important;
}

.react-select__multi-value {
  margin: 0 !important;
}

.react-select__clear-indicator,
.react-select-new-page__clear-indicator {
  display: none !important;
}

.basic-multi-select .react-select__control {
  padding-left: 4px;
}

.react-select__control,
.react-select-new-page__control {
  padding-left: 16px;
  outline: 1px !important;
}

.react-select__control:hover,
.react-select-new-page__control:hover {
  outline-color: #ceced2 !important;
}

.react-select__control--is-focused,
.react-select-new-page__control--is-focused {
  outline-color: #3f7cdb !important;
  outline-width: 1px !important;
}

.react-select__indicator-separator,
.react-select-new-page__indicator-separator {
  display: none !important;
}

.react-select__indicator,
.react-select-new-page__indicator {
  padding: 15px !important;
  padding-right: 16px !important;
}

/* multiselect */
.react-select__multi-value {
  background-color: #2a5392 !important;
  color: #d9e5f8;
  border-radius: 25px !important;
  flex-direction: row-reverse;
  gap: 4px;
  align-items: center !important;
  padding: 3px 8px !important;
  margin: 0 !important;
}

.react-select__multi-value__label {
  color: inherit !important;
  text-transform: uppercase;
  font-size: 10px !important;
  font-weight: 500;
  line-height: 12px;
  padding: 0 !important;
  position: relative;
  top: 3px;
  margin-bottom: 3px;
}

.react-select__multi-value__remove {
  padding: 0 !important;
}

.react-select__multi-value__remove:hover {
  background-color: transparent !important;
}

.react-select__control--is-focused:active,
.react-select__control--is-focused:focus-within {
  outline-color: #3f7cdb !important;
  outline-width: 1px !important;
  border-color: #3f7cdb !important;
}

.react-select-new-page__menu-list {
  height: auto;
}

.react-select__group-heading span:first-child {
  font-size: 10px !important;
  font-weight: 500 !important;
  color: #8e90a9 !important;
}

.react-select__control,
.react-select-new-page__control {
  padding-left: 16px !important;
  outline: 1px !important;
}

.react-select__placeholder,
.react-select-new-page__placeholder {
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #8e90a9 !important;
}

.react-select-sm .react-select__single-value {
  font-size: 10px !important;
  font-weight: 500 !important;
  color: #4a4a68 !important;
}

.react-select-sm .react-select__control {
  padding-left: 8px !important;
  min-height: 31px !important;
}

.react-select-sm .react-select__indicator {
  padding: 0 8px !important;
}

.react-select-sm .react-select__value-container {
  height: 17px !important;
}

.react-select-sm .react-select__menu {
  min-width: 180px !important;
}

.react-select-sm .react-select__option {
  font-size: 12px !important;
}

.audit-trial .react-select__control {
  width: 320px;
}
