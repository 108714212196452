@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family: 'Atakk', sans-serif;
  scroll-behavior: smooth;
}

::-webkit-search-cancel-button {
  display: none;
}

@layer base {
  @font-face {
    font-family: 'Atakk';
    src: url('./assets/fonts/Atakk-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Atakk';
    src: url('./assets/fonts/Atakk-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Atakk';
    src: url('./assets/fonts/Atakk-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Atakk';
    src: url('./assets/fonts/Atakk-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
}

@layer components {
  .input {
    @apply bg-grey20 border-0 outline-0 py-[10px] px-4 h-12 max-w-[322px] w-full rounded;
  }
  .input[data-widerinput] {
    @apply bg-grey20 border-0 outline-0 py-[10px] px-4 h-12 max-w-[384px] w-full rounded;
  }

  .label {
    @apply max-w-[322px] w-full;
  }
  .label2 {
    @apply w-full;
  }

  .label[data-widerlabel] {
    @apply max-w-[384px] w-full;
  }

  textarea {
    @apply w-full h-32 px-4 pt-3 rounded bg-white-900 outline outline-1 outline-grey-100 placeholder:text-grey-300 text-sm;
  }

  textarea[data-smallertextarea] {
    @apply w-full px-2 pt-2 rounded h-22 bg-white-900 outline outline-1 outline-grey-100 placeholder:text-grey-300;
  }
}

aside::-webkit-scrollbar {
  position: relative;
  right: 40px;
  width: 5px !important;
}

aside::-webkit-scrollbar-track {
  background: none !important;
}

aside::-webkit-scrollbar-thumb {
  background-color: #ceced2 !important;
  border-radius: 10px !important;
}

.ck-content {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
}

.ck-content h1 {
  font-size: 28px;
  font-weight: bold;
  margin: 32px 0;
}

.ck-content h2 {
  font-size: 24px;
  font-weight: bold;
  margin: 28px 0;
}

.ck-content h3 {
  font-size: 20px;
  font-weight: bold;
  margin: 24px 0;
}

.ck-content p {
  margin: 16px 0;
}

.ck-content ul {
  list-style: disc;
  margin: 16px 0;
  padding-left: 32px;
}

.ck-content ol {
  list-style: decimal;
  margin: 16px 0;
  padding-left: 32px;
}

.ck-content li {
  margin: 8px 0;
}

.ck-content a {
  color: #0077cc;
  text-decoration: none;
  border-bottom: 1px solid #0077cc;
}

.ck-content a:hover {
  color: #004466;
  border-bottom-color: #004466;
}

/* activity log styling */
.activity-parent {
  grid-template-columns: 0.8fr 4fr;
}

.activity-description:before {
  transform: translate(-50%, -50%);
}


@keyframes skeleton-animation {
  0% {
    right: 100%;
  }

  50% {
    right: -128px;
  }

  100% {
    right: 100%;
  }
}

.skeleton {
  overflow: hidden;
  width: 328px;
  height: auto;
  display: grid;
  grid-template-columns: 128px 200px;
  grid-gap: 8px;
  position: relative;
  margin-bottom: 16px;
  /* margin-left: 7rem; */
  background: #eee;
}

.skeleton:before {
  display: block;
  position: absolute;
  width: 128px;
  height: 200px;
  background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0),
          #fff,
          rgba(255, 255, 255, 0)
  );
  content: '';
  animation: skeleton-animation 1.25s infinite;
}

.skeleton .s-img {
  width: 128px;
  height: 78px;
  background: #eee;
  grid-row: 1 / 4;
}

.skeleton .s-line {
  height: 10px;
  background: #eee;
}

.skeleton .s-line.first {
  width: 100%;
}

.skeleton .s-line.second {
  width: 75%;
}

.skeleton .s-line.third {
  width: 50%;
}
