@import './assets/styles/pagination.scss';
@import './assets/styles/react-select.css';
@import './assets/styles/datepicker.css';

input,
textarea {
  border-radius: 4px !important;
  border: 1px solid #f6f6f8 !important;
}

input:focus-visible,
textarea:focus-visible {
  outline: none !important;
}

.input:focus-visible {
  border: 1px solid #3977de !important;
}

.app-image {
  background-position: center !important;
  background-size: cover !important;
  border-radius: 50%;
  background-repeat: no-repeat !important;
  display: inline-block;
  height: 30px;
  width: 30px;
}

.border-tableGrey:hover {
  background: rgb(248, 248, 248);
  cursor: pointer;
}

[type='file'] {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}

[type='file'] + label {
  color: #3f7cdb;
  font-weight: 400;
}
[type='file']:focus + label,
[type='file'] + label:hover {
  cursor: pointer;
}
[type='file']:focus-visible + label {
  outline: 2px solid #000;
  border-radius: 2px;
}
