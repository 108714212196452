.pagination {
  justify-content: flex-start !important;
  display: flex;
  padding-left: 0;
  list-style: none;
  margin: 0;
}

.page-item {
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
}

.page-item .page-link {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 7px 0 0;
  min-height: 32px !important;
  min-width: 32px !important;
  max-height: 32px !important;
  max-width: 32px !important;
  border-radius: 4px;
  font-weight: 400;
  text-align: center;
  font-size: 0.875rem;
  border: none !important;
  box-shadow: none !important;
  color: #677189;
  text-decoration: none;
}

.page-item a.page-link:hover {
  background-color: #3977de;
  color: #ffffff;
}

.page-item.active a.page-link {
  font-weight: 400;
  color: #ffffff !important;
  background-color: #3977de;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.pagination {
  & > li:first-child .page-link {
    max-width: initial !important;
    &:hover {
      background-color: #fff !important;
      color: #677189;
    }
    display: flex;
    align-items: center;
    span {
      display: none;
    }
    &::before {
      content: '\276E' !important;
      font-size: 0.5rem;
      margin-right: 10px;
    }
    &::after {
      content: 'Previous' !important;
    }
  }
  & > li:last-child .page-link {
    max-width: initial !important;
    &:hover {
      background-color: #fff !important;
      color: #677189;
    }
    display: flex;
    align-items: center;
    span {
      display: none;
    }
    &::after {
      content: '\276F' !important;
      font-size: 0.5rem;
      margin-left: 10px;
    }
    &::before {
      content: 'Next' !important;
    }
  }
}
